import React from 'react';
import { Helmet } from 'react-helmet';

export default function Resume() {
    const pdfPath = require('../assets/resume.pdf');

    return (
        <div>
            <Helmet>
                <title>Yasin Sefa Kırman - Özgeçmiş (CV)</title>
            </Helmet>
            <iframe src={pdfPath ?? ""}
                    title={"Yasin Sefa Kırman CV"}
                    style={{
                        width: "100%",
                        height: "100vh",
                    }}></iframe>
        </div>
    );
}