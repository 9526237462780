import project1 from '../assets/project-1.png';
import project3 from '../assets/project-3.png';
import arrow from '../assets/arrow.png';
import plandyImage from '../assets/project-plandy.jpg';
export default function ProjectsSection() {
    return (
        <section id="projects" style={{
            minHeight: '100vh',
        }}>
            <p className="section__text__p1">Son Projelerimi Keşfedin</p>
            <h1 className="title">Projelerim</h1>
            <div className="experience-details-container">
                <div className="about-containers">
                    <div className="details-container color-container">
                        <div className="article-container">
                            <img
                                src={project1 ?? ""}
                                alt="KliGem"
                                className="project-img"
                            />
                        </div>
                        <h2 className="experience-sub-title project-title">KliGem</h2>

                        <p className="project-description">
                            Full Stack developer olarak KliGem projesinde görev aldım. KliGem, klinik işletmelerinin
                            daha kolay ve hızlı bir şekilde yönetilebilmesi için geliştirilmiş bir masaüstü
                            uygulamasıdır.
                        </p>

                        <div className="btn-container">
                            <button
                                className="btn btn-color-2 project-btn"
                                onClick={() => {
                                    window.open('https://www.kligem.com', '_blank');
                                }}
                            >
                                Website
                            </button>
                        </div>
                    </div>
                    <div className="details-container color-container">
                        <div className="article-container">
                            <img
                                src={plandyImage ?? ""}
                                alt="Plandy.co"
                                className="project-img"
                            />
                        </div>
                        <h2 className="experience-sub-title project-title">Plandy</h2>

                        <p className="project-description">
                            Backend developer olarak Plandy projesinde görev aldım. Plandy, kullanıcıların günlük
                            planlarını oluşturabileceği bir web sitesidir. Kullanıcılar, günlük planlarını
                            oluşturabilir, günlük planlarını düzenleyebilir ve günlük planlarını silebilirler.
                        </p>

                        <div className="btn-container">
                            <button
                                className="btn btn-color-2 project-btn"
                                onClick={() => {
                                    window.open('https://www.plandy.co', '_blank')
                                }}
                            >
                                Website
                            </button>
                        </div>
                    </div>
                    <div className="details-container color-container">
                        <div className="article-container">
                            <img
                                src={project3 ?? ""}
                                alt="Project 3"
                                className="project-img"
                            />
                        </div>
                        <h2 className="experience-sub-title project-title">Horhoria Flowers</h2>

                        <p className="project-description">
                            Full Stack web developer olarak Horhoria Flowers projesini hayata geçirdim.
                            Horhoria Flowers, kullanıcıların çiçek siparişi verebileceği bir e-ticaret web sitesidir.
                        </p>

                        <div className="btn-container">
                            <button
                                className="btn btn-color-2 project-btn"
                                onClick={() => {
                                    window.open('https://www.horhoriaflowers.com', '_blank')
                                }}
                            >
                                Website
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <img
                src={arrow ?? ""}
                alt="Arrow icon"
                className="icon arrow"
                onClick={() => {
                    document.getElementById('contact').scrollIntoView({behavior: 'smooth'});
                }}
            />
        </section>
    )
}