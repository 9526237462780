import emailIcon from '../assets/email.png';
import linkedinIcon from '../assets/linkedin.png';

export default function ContactSection() {
    return (
        <section id="contact" style={{
            minHeight: '100vh',
            marginTop: '50px',
        }}>
            <p className="section__text__p1">Bana Ulaşın</p>
            <h1 className="title">Benimle İletişime Geçin</h1>
            <div className="contact-info-upper-container">
                <div className="contact-info-container">
                    <img
                        src={emailIcon ?? ""}
                        alt="Email icon"
                        className="icon contact-icon email-icon"
                    />
                    <p><a href="mailto:yasekirman@gmail.com" target="_blank">yasekirman@gmail.com</a></p>
                </div>
                <div className="contact-info-container">
                    <img
                        src={linkedinIcon ?? ""}
                        alt="LinkedIn icon"
                        className="icon contact-icon"
                    />
                    <p><a href="https://www.linkedin.com/in/yasinsefakirman/" target="_blank">LinkedIn</a></p>
                </div>
            </div>
        </section>
    )
}