import {Nav, Navbar} from "react-bootstrap";

export default function MyNavbar() {
    return (
        <Navbar className="px-4" expand="lg">
            <Navbar.Brand href="#home" className="logo">Yasin Sefa Kırman</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav"/>
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ml-auto">
                    <Nav.Link href="#about">Hakkımda</Nav.Link>
                    <Nav.Link href="#experience">Deneyim</Nav.Link>
                    <Nav.Link href="#projects">Projeler</Nav.Link>
                    <Nav.Link href="#contact">İletişim</Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
}