import React from "react";
import ProfileImage from "../assets/profile-pic.png";
import linkedin from "../assets/linkedin.png";
import github from "../assets/github.png";

export default function ProfileSection() {
    return (
        <section id="profile">
            <div className="section__pic-container">
                <img src={ProfileImage ?? ""} alt="Yasin Sefa Kırman profile picture"/>
            </div>
            <div className="section__text">
                <p className="section__text__p1">Merhaba, Ben</p>
                <h1 className="title">Yasin Sefa Kırman</h1>
                <p className="section__text__p2">Full Stack Developer</p>
                <div className="btn-container">
                    <button
                        className="btn btn-color-2"
                        onClick={() => {
                            //redirect to /view-cv in this tab
                            window.open("/view-resume", "_blank");
                        }}
                    >
                        CV'yi Görüntüle
                    </button>
                    <button className="btn btn-color-2" onClick={() => {
                        //jump to contact section
                        const contactSection = document.getElementById("contact");
                        contactSection.scrollIntoView({behavior: "smooth"});
                    }}>
                        İletişime Geç
                    </button>
                </div>
                <div id="socials-container">
                    <img
                        src={linkedin ?? ""}
                        alt="LinkedIn profilim"
                        className="icon"
                        onClick={() => {
                            window.open("https://www.linkedin.com/in/yasinsefakirman/")
                        }}
                    />
                    <img
                        src={github ?? ""}
                        alt="Github profilim"
                        className="icon"
                        onClick={() => {
                            window.open("https://github.com/YasinSefa15")
                        }}
                    />
                </div>
            </div>
        </section>
    )
}