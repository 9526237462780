import experience from "../assets/experience.png";
import education from "../assets/education.png";
import arrow from '../assets/arrow.png';

export default function AboutSection() {
    return (
        <section id="about">
            <p className="section__text__p1">Daha Fazla Bilgi Edinin</p>
            <h1 className="title">Hakkımda</h1>
            <div className="section-container">
                <div className="about-details-container">
                    <div className="about-containers">
                        <div className="details-container">
                            <img
                                src={experience ?? ""}
                                alt="Experience icon"
                                className="icon"
                            />
                            <h3>Deneyim</h3>
                            <p>2+ Yıl <br/>Fullstack Development</p>
                        </div>
                        <div className="details-container">
                            <img
                                src={education ?? ""}
                                alt="Education icon"
                                className="icon"
                            />
                            <h3>Eğitim</h3>
                            <p>Lisans<br/>Marmara Üniversitesi<br/>Bilgisayar Mühendisliği (İngilizce)</p>
                        </div>
                    </div>
                    <div className="text-container">
                        <p>
                            Son üç yıldır kodlamaya kendimi adamış bulunmaktayım.
                            Çeşitli web, masaüstü ve mobil geliştirme projelerinde değerli deneyimler kazandım.
                            Odak noktam, gerçek yaşam zorluklarına pratik çözümler sunmak için
                            RESTful API'ler tasarlamak ve uygulamaktır. Sürekli öğrenme arzusuyla motive oluyorum
                            ve teknolojik gelişmelerin öncüsünde kalmak beni heyecanlandırıyor.
                            Bir geliştirici adayı olarak, yeteneklerimi ve yaratıcılığımı kullanarak
                            olumlu bir etki yaratan yenilikçi projeler oluşturmak için kendimi adamış hissediyorum.
                        </p>
                    </div>
                </div>
            </div>
            <img
                src={arrow ?? ""}
                alt="Arrow icon"
                className="icon arrow"
                onClick={() => {
                    document.getElementById('experience').scrollIntoView({behavior: 'smooth'});
                }}
            />
        </section>
    )
}