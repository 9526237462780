import React from 'react';
import checkmark from '../assets/checkmark.png';
import arrow from '../assets/arrow.png';


export default function ExperienceSection() {
    return (
        <>
            <section id="experience">
                <p className="section__text__p1">Deneyimlerimi Keşfedin</p>
                <h1 className="title">Deneyimlerim</h1>
                <div className="experience-details-container">
                    <div className="about-containers">
                        <div className="details-container">
                            <h2 className="experience-sub-title">Frontend Geliştirme</h2>
                            <div className="article-container">
                                <article>
                                    <img
                                        src={checkmark ?? ""}
                                        alt="Experience icon"
                                        className="icon"
                                    />
                                    <div>
                                        <h3>React</h3>
                                    </div>
                                </article>
                                <article>
                                    <img
                                        src={checkmark ?? ""}
                                        alt="Experience icon"
                                        className="icon"
                                    />
                                    <div>
                                        <h3>JavaScript</h3>
                                    </div>
                                </article>
                                <article>
                                    <img
                                        src={checkmark ?? ""}
                                        alt="Experience icon"
                                        className="icon"
                                    />
                                    <div>
                                        <h3>HTML</h3>
                                    </div>
                                </article>
                                <article>
                                    <img
                                        src={checkmark ?? ""}
                                        alt="Experience icon"
                                        className="icon"
                                    />
                                    <div>
                                        <h3>TypeScript</h3>
                                    </div>
                                </article>
                                <article>
                                    <img
                                        src={checkmark ?? ""}
                                        alt="Experience icon"
                                        className="icon"
                                    />
                                    <div>
                                        <h3>CSS</h3>
                                    </div>
                                </article>
                                <article>
                                    <img
                                        src={checkmark ?? ""}
                                        alt="Experience icon"
                                        className="icon"
                                    />
                                    <div>
                                        <h3>Bootstrap</h3>
                                    </div>
                                </article>

                            </div>
                        </div>
                        <div className="details-container">
                            <h2 className="experience-sub-title">Backend Geliştirme</h2>
                            <div className="article-container">
                                <article>
                                    <img
                                        src={checkmark ?? ""}
                                        alt="Experience icon"
                                        className="icon"
                                    />
                                    <div>
                                        <h3>MySQL</h3>
                                    </div>
                                </article>
                                <article>
                                    <img
                                        src={checkmark ?? ""}
                                        alt="Experience icon"
                                        className="icon"
                                    />
                                    <div>
                                        <h3>NodeJS</h3>
                                    </div>
                                </article>
                                <article>
                                    <img
                                        src={checkmark ?? ""}
                                        alt="Experience icon"
                                        className="icon"
                                    />
                                    <div>
                                        <h3>Laravel - PHP</h3>
                                    </div>
                                </article>
                                <article>
                                    <img
                                        src={checkmark ?? ""}
                                        alt="Experience icon"
                                        className="icon"
                                    />
                                    <div>
                                        <h3>Git</h3>
                                    </div>
                                </article>
                                <article>
                                    <img
                                        src={checkmark ?? ""}
                                        alt="Experience icon"
                                        className="icon"
                                    />
                                    <div>
                                        <h3>Redis</h3>
                                    </div>
                                </article>
                            </div>
                        </div>
                    </div>
                </div>
                <img
                    src={arrow ?? ""}
                    alt="Arrow icon"
                    className="icon arrow"
                    onClick={() => {
                        document.getElementById('projects').scrollIntoView({behavior: 'smooth'});
                    }}
                />
            </section>

        </>
    )
}