export default function Footer() {
    return (
        <footer>
            <nav className="d-flex justify-content-center">
                <div className="nav-links-container">
                    <ul className="nav-links">
                        <li><a href="#about">Hakkımda</a></li>
                        <li><a href="#experience">Deneyim</a></li>
                        <li><a href="#projects">Projeler</a></li>
                        <li><a href="#contact">İletişim</a></li>
                    </ul>
                </div>
            </nav>
        </footer>
    );
}
