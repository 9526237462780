import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './AppMediaQueries.css';
import {Route, Routes} from "react-router-dom";
import Main from "./views/Main";
import Resume from "./views/Resume";

function App() {
    return (
        <Routes>
            <Route path="/" element={<Main/>}/>
            <Route path="/view-resume" element={<Resume/>}/>
            <Route path="*" element={<Main/>}/>
        </Routes>

    );
}

export default App;
