import MyNavbar from "../components/MyNavbar";
import ProfileSection from "../components/ProfileSection";
import AboutSection from "../components/AboutSection";
import ExperienceSection from "../components/ExperienceSection";
import ProjectsSection from "../components/ProjectsSection";
import ContactSection from "../components/ContactSection";
import Footer from "../components/Footer";
import React from "react";

export default function Main() {
  return (
      <>
          <MyNavbar/>

          <ProfileSection/>

          <AboutSection/>

          <ExperienceSection/>

          <ProjectsSection/>

          <ContactSection/>

          <Footer/>
      </>
  );
}